<template>
  <div>
    <edit-space
      v-if="showEditSpace"
      :showModal="showEditSpace"
      :onClickCancel="hideModal"
    />

    <ui-section-header :showEdit="true" :editFunction="setShowEditSpace">
      <template v-slot:title>Basic data</template>
    </ui-section-header>

    <table class="table is-striped is-fullwidth">
      <tbody>
        <tr>
          <td>
            <label>Number</label>
          </td>
          <td>{{ space.Number }}</td>
        </tr>
        <tr>
          <td>
            <label>External code</label>
          </td>
          <td>{{ space.ExternalCode }}</td>
        </tr>
        <tr>
          <td>
            <label>Width</label>
          </td>
          <td>{{ space.Width }}</td>
        </tr>
        <tr>
          <td>
            <label>Length</label>
          </td>
          <td>{{ space.Length }}</td>
        </tr>
        <tr>
          <td>
            <label>Height</label>
          </td>
          <td>{{ space.Height }}</td>
        </tr>
        <tr>
          <td>
            <label>Area</label>
          </td>
          <td>{{ space.M2 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditSpace = () => import('@/components/Spaces/ModalEditSpace')

export default {
  components: {
    'edit-space': ModalEditSpace,
  },

  props: {},

  data() {
    return {
      showEditSpace: false,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),
  },

  created() {},

  methods: {
    setShowEditSpace() {
      this.showEditSpace = true
    },

    hideModal() {
      this.showEditSpace = false
    },
  },
}
</script>

<style></style>
