var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showEditSpace)?_c('edit-space',{attrs:{"showModal":_vm.showEditSpace,"onClickCancel":_vm.hideModal}}):_vm._e(),_c('ui-section-header',{attrs:{"showEdit":true,"editFunction":_vm.setShowEditSpace},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Basic data")]},proxy:true}])}),_c('table',{staticClass:"table is-striped is-fullwidth"},[_c('tbody',[_c('tr',[_vm._m(0),_c('td',[_vm._v(_vm._s(_vm.space.Number))])]),_c('tr',[_vm._m(1),_c('td',[_vm._v(_vm._s(_vm.space.ExternalCode))])]),_c('tr',[_vm._m(2),_c('td',[_vm._v(_vm._s(_vm.space.Width))])]),_c('tr',[_vm._m(3),_c('td',[_vm._v(_vm._s(_vm.space.Length))])]),_c('tr',[_vm._m(4),_c('td',[_vm._v(_vm._s(_vm.space.Height))])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(_vm._s(_vm.space.M2))])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('label',[_vm._v("Number")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('label',[_vm._v("External code")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('label',[_vm._v("Width")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('label',[_vm._v("Length")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('label',[_vm._v("Height")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('label',[_vm._v("Area")])])
}]

export { render, staticRenderFns }